import { isEmpty } from 'lodash';

export function listToGroupedDropdownOptions(list, groupLabels, options) {
	const _options = {
		valueKey: 'value',
		textKey: 'text',
		groupBy: 'column',
		...options,
	};

	const _list = list.map((item) => ({
		...item,
		value: item[_options.valueKey],
		text: item[_options.textKey],
	}));
	const grouped = _list.customGroupBy(_options.groupBy);
	const listForDropdown = [];

	for (const [groupIndex, groupItems] of Object.entries(grouped)) {
		listForDropdown.push({ label: groupLabels[groupIndex], options: groupItems });
	}

	return listForDropdown;
}

export function findColumnIndexByModel(columns, model) {
	return columns.findIndex((c) => c.model === model);
}

export function setDropdownOptions(columns, model, values, columnIndex = null, dropdownOptionsKey = 'dropdownOptions') {
	const colIndex = columnIndex || findColumnIndexByModel(columns, model);

	if (!columns[colIndex].filterOptions) {
		columns[colIndex].filterOptions = {};
	}

	columns[colIndex].filterOptions[dropdownOptionsKey] = values;

	return columns;
}

export function setCellFormatOptions(columns, model, cellFormatOptions, columnIndex) {
	const colIndex = columnIndex || findColumnIndexByModel(columns, model);

	columns[colIndex].cellFormatOptions = cellFormatOptions;

	return columns;
}

export function getColumnFilterOptions(columns, modelName) {
	if (!columns.length) {
		return {};
	}

	const model = columns.find((column) => column.model === modelName);
	if (!model) {
		return {};
	}

	return model.filterOptions || {};
}

export function getColumnDropdownOptions(columns, modelName, dropdownOptionsKey = 'dropdownOptions') {
	const filterOptions = getColumnFilterOptions(columns, modelName);

	return filterOptions[dropdownOptionsKey] || {};
}

export function getColumnDropdownOptionsAsDict(columns, modelName, dropdownOptionsKey = 'dropdownOptions') {
	const filterOptions = getColumnFilterOptions(columns, modelName, dropdownOptionsKey);

	return filterOptions.dropdownOptions.reduce((texts, dropdownOption) => {
		if (dropdownOption.value !== null) {
			texts[dropdownOption.value] = dropdownOption.text;
		}
		return texts;
	}, {});
}
