<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="objectpropertie3489"
		tooltipMsg="pimanualofobjec"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OspropOrder', ascending: 1 }"
		modelId="OspropId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<!--
		<template #PtypeId="list">
			{{ list.row.PtypeIdText }}
		</template>
		-->
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';

import { EntityTypes } from './services/entity-types.service';
import { tableColumns } from './object-simple-properties-table.data';

export default {
	name: 'ObjectSimplePropertyTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ObjectSimpleProperty',
			types: [],
		};
	},

	async created() {
		this.types = await EntityTypes.types;

		this.columns = this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.objectSimplePropertyDetailName,
				params: { id },
			});
		},

		prepareColumns(columns) {
			let cols = setDropdownOptions(columns, 'PtypeId', EntityTypes.forDropdown(this.types));

			return cols;
		},

		transformResponseData(data) {
			data.data = data.data.map((item) => ({
				...item,
				PtypeIdText: this.getPtypeIdText(item.PtypeId),
			}));
			return data;
		},

		getPtypeIdText(PtypeId) {
			const found = this.types.find((type) => type.PtypeId === PtypeId);
			return found ? found.PtypeNameSk : null;
		},
	},
};
</script>