import apiCommon from '@/services/api/common.api';
import serviceEnums from '@/services/service/enums.service';

class EntityTypesClass {
	constructor() {
		this.groupLabels = serviceEnums.getEnumAsKeyValue('objectSimplePropertyTypeEntityType');
		this.types = apiCommon.getStandardList({}, 'ObjectSimplePropertyType').then((res) => res.data);
	}

	forDropdown(_types) {
		const types = _types.map((t) => ({
			...t,
			value: t.PtypeId,
			text: t.PtypeNameSk,
		}));
		const grouped = types.customGroupBy('PtypeEntityType');
		const tfd = [];

		for (const [groupIndex, groupTypes] of Object.entries(grouped)) {
			tfd.push({ label: this.groupLabels[groupIndex], options: groupTypes });
		}

		return tfd;
	}

	forVfgDropdown(_types) {
		return _types.map((t) => ({
			id: t.PtypeId,
			name: t.PtypeNameSk,
			group: this.groupLabels[t.PtypeEntityType],
		}));
	}
}

const EntityTypes = new EntityTypesClass();

export { EntityTypes };
