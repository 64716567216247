export const tableColumns = [
	{
		model: 'OspropId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'OspropNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OspropNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OspropNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OspropNameDe',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OspropNameEn',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OspropNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OspropOrder',
		i18n: 'order',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		colType: 'boolean',
		model: 'OspropInSearch',
		i18n: 'insearch',
		sortable: true,
	},
	{
		colType: 'boolean',
		model: 'IsBasic',
		i18n: 'basic',
		sortable: true,
	},
	{
		model: 'PtypeId',
		i18n: 'section',
		filter: 'dropdown',
		filterInitValue: 5,
		filterOptions: {
			dropdownOptions: [],
		},
		cellFormat: 'modelToDisplay',
		cellFormatOptions: {
			modelToDisplay: 'PtypeIdText',
		},
	},
	{
		colType: 'boolean',
		model: 'IsAllowedInAu',
		i18n: 'inaueditingform',
		sortable: true,
		hidden: true,
	},
];
